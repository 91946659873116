class ZIDXAccountSavedSearches implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountSavedSearchesContainer";
    }
    getMatchURL(){
        return "/account/saved-searches";
    }
    render(){
        // console.log("saved searches");
    }
}